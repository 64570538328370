@import "../../scss/theme.scss";
@import "../../scss/responsive.scss";

.dashboard-container {
  font-family: "ApexNew-Medium";
  @include themify($themes) {
    color: themed("textColor");
  }

  .links-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    margin-left: -20px;
    .dashboard-links {
      a {
        padding: 10px 20px 8px;
        font-weight: bold;
        @include themify($themes) {
          color: themed("textColor");
        }
        &:hover {
          text-decoration: none;
        }
        &.active-link {
          position: relative;
          @include themify($themes) {
            color: themed("buttonTextHoverColor");
          }
          &::after {
            content: "";
            position: absolute;
            display: block;
            width: 25px;
            height: 2px;
            @include themify($themes) {
              background-color: themed("successSubmitColor");
            }
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
        @include break-point(mobile-view) {
          padding: 5px 10px 4px;
          font-size: 0.875em;
        }
      }
      @include break-point(mobile-view) {
        text-align: center;
      }
    }
    @include break-point(mobile-view) {
      display: block;
      margin: 0;
      padding: 10px 0 15px;
    }
  }

  .office-select {
    .react-select-container {
      width: 160px;
      & input {
        color: white;
      }
      .react-select__control {
        min-height: 30px;
        .react-select__value-container {
          height: 30px;
        }
        .react-select__indicators {
          height: 30px;
        }
      }
      .react-select__single-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.875em;
      }
      .react-select__menu {
        font-size: 0.75em;
        .react-select__option {
          padding: 4px 12px;
        }
      }
    }
    @include break-point(mobile-view) {
      margin-top: 15px;
      display: flex;
      justify-content: center;
    }
  }

  .overview-container,
  .sales-container,
  .pm-container,
  .yoy-container,
  .market-tracker-container,
  .test1-container,
  .test2-container {
    width: 100%;
    height: calc(82vh - 20px);

    .overview-report,
    .sales-report,
    .pm-report,
    .yoy-report,
    .market-tracker-report,
    .test1-report,
    .test2-report {
      height: 100%;
      width: 100%;
      @include themify($themes) {
        background-color: themed("backgroundColor");
        border-color: themed("fieldFocusBorder");
      }
      iframe {
        border: none;
      }
    }
    @include break-point(mobile-view) {
      height: calc(78vh - 30px);
    }
    @include break-point(tablet-view) {
      height: 78vh;
    }
    @include break-point(large-screen-height) {
      height: calc(85vh - 10px);
    }
    @include break-point(landscape) {
      height: 58vh;
    }
  }

  .report-error-container {
    max-width: 450px;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .error-wrapper {
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      h4 {
        margin: 0;
        font-size: 1.2em;
        @include break-point(mobile-view) {
          font-size: 0.875em;
        }
      }
      @include themify($themes) {
        background-color: themed("buttonColor");
      }
    }
    @include themify($themes) {
      color: themed("textColor");
    }
    @include break-point(mobile-view) {
      max-width: 260px;
    }
    @include break-point(landscape) {
      transform: translate(-50%, 50%);
    }
  }
  .page-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    min-width: 400px;
    font-size: 1.2em;
    @include themify($themes) {
      background-color: themed("buttonColor");
    }
    p {
      margin: 15px 0 0;
      @include break-point(mobile-view) {
        margin: 5px 0 0;
      }
    }
    @include break-point(mobile-view) {
      min-width: 300px;
      font-size: 1em;
    }
    @include break-point(landscape) {
      transform: translate(-50%, 50%);
    }
  }
}
