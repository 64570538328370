@import "../../scss/theme.scss";

.login-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .live-logo {
    max-width: 142px;
    margin-bottom: 40px;
  }
  .error-box {
    max-width: 325px;
    text-align: center;
    padding: 0.5rem 0.4rem;
    margin-top: 30px;
    border-radius: 2px;
    color: white;
    font-weight: bold;
    font-size: 0.9rem;
    @include themify($themes) {
      background-color: themed("errorDeleteColor");
      color: themed("buttonTxtColor");
    }
  }
}
